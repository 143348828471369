.top-menu {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 0 56px;
  background: rgba(23, 31, 77, 0.8);
  z-index: 5000;
}
.top-menu:after {
  content: '';
  display: table;
  clear: both;
}
@media screen and (max-width: 1280px) {
  .top-menu {
    padding: 0;
  }
}
.top-menu__logo {
  float: left;
}
.top-menu__links {
  float: right;
}
@media screen and (max-width: 1280px) {
  .top-menu__links {
    display: none;
  }
}
.top-menu__hamburger {
  float: right;
  display: none;
  width: 24px;
  height: 24px;
  padding: 24px 16px;
  cursor: pointer;
}
.top-menu__hamburger svg {
  will-change: fill;
  transition: fill 150ms ease;
  fill: #fff;
}
.top-menu__hamburger svg:hover,
.top-menu__hamburger svg:focus {
  fill: #189EE9;
}
@media screen and (max-width: 1280px) {
  .top-menu__hamburger {
    display: block;
  }
}
.top-menu__link {
  display: inline-block;
  padding: 24px;
  line-height: 32px;
  font-size: 24px;
  color: #fff;
  text-decoration: none;
  transition: color 150ms ease;
}
.top-menu__link svg {
  will-change: fill;
  transition: fill 150ms ease;
  fill: #fff;
}
@media screen and (max-width: 1280px) {
  .top-menu__link {
    display: block;
    line-height: 24px;
    font-size: 16px;
    text-align: right;
    padding: 18px 16px;
  }
}
.top-menu__link:hover,
.top-menu__link:focus {
  color: #189EE9;
}
.top-menu__link:hover svg,
.top-menu__link:focus svg {
  fill: #189EE9;
}
.top-menu__link_active {
  color: #189EE9;
}
.top-menu__link_active svg {
  fill: #189EE9;
}
@media screen and (max-width: 1280px) {
  .top-menu__link-index {
    display: inline-block;
    text-align: left;
    padding: 24px 16px;
  }
}
.top-menu__logo-image {
  width: 32px;
  height: 32px;
  margin-right: 16px;
  vertical-align: top;
}
@media screen and (max-width: 1280px) {
  .top-menu__logo-image {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }
}
.top-menu-mobile {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #171F4D;
  z-index: 6000;
}
.top-menu-mobile__top:after {
  content: '';
  display: table;
  clear: both;
}
.top-menu-mobile__closer {
  float: right;
  padding: 24px 12px;
}
.top-menu-mobile__closer svg {
  fill: #fff;
  will-change: fill;
  transition: fill 150ms ease;
}
.top-menu-mobile__closer svg:hover,
.top-menu-mobile__closer svg:focus {
  fill: #189EE9;
}
.top-menu-mobile__closer svg_active {
  fill: #189EE9;
}
.top-menu-mobile__additional {
  margin-top: 60px;
  padding: 16px;
}
.top-menu-mobile__add-link {
  display: block!important;
  margin-bottom: 16px;
}
.footer-menu:after {
  content: '';
  display: table;
  clear: both;
}
.footer-menu__logo {
  float: left;
}
.footer-menu__links {
  float: right;
}
.footer-menu__hamburger {
  float: right;
  display: none;
  width: 24px;
  height: 24px;
  padding: 24px 16px;
  cursor: pointer;
}
.footer-menu__hamburger svg {
  will-change: fill;
  transition: fill 150ms ease;
  fill: #fff;
}
.footer-menu__hamburger svg:hover,
.footer-menu__hamburger svg:focus {
  fill: #189EE9;
}
.footer-menu__link {
  display: inline-block;
  padding: 24px;
  line-height: 32px;
  font-size: 24px;
  color: #fff;
  text-decoration: none;
  transition: color 150ms ease;
}
.footer-menu__link svg {
  will-change: fill;
  transition: fill 150ms ease;
  fill: #fff;
}
.footer-menu__link:hover,
.footer-menu__link:focus {
  color: #189EE9;
}
.footer-menu__link:hover svg,
.footer-menu__link:focus svg {
  fill: #189EE9;
}
.footer-menu__link_active {
  color: #189EE9;
}
.footer-menu__link_active svg {
  fill: #189EE9;
}
.footer-menu__logo-image {
  width: 32px;
  height: 32px;
  margin-right: 16px;
  vertical-align: top;
}
@media screen and (max-width: 1280px) {
  .footer-menu:after {
    content: '';
    display: table;
    clear: both;
  }
  .footer-menu__link {
    font-size: 18px;
    padding: 8px;
  }
  .footer-menu__logo-image {
    width: 32px;
    height: 32px;
    margin-right: 16px;
    vertical-align: top;
  }
}
@media screen and (max-width: 960px) {
  .footer-menu:after {
    content: '';
    display: none;
    clear: both;
  }
  .footer-menu__logo,
  .footer-menu__links {
    float: none;
  }
  .footer-menu__link {
    display: block;
    font-size: 16px;
    padding: 8px;
    text-align: center;
  }
  .footer-menu__link-index {
    margin-top: 60px;
    font-size: 24px;
  }
  .footer-menu__link-index svg {
    width: auto;
  }
  .footer-menu__logo-image {
    width: 32px;
    height: 32px;
    margin-right: 16px;
  }
}
.tabs__header {
  position: static;
}
.tabs__title {
  display: inline-block;
  margin-right: 32px;
  font-size: 60px;
  line-height: 70px;
  user-select: none;
  color: #189EE9;
  cursor: pointer;
}
.tabs__title_active {
  border-bottom: 2px solid #189EE9;
  color: #272F5A;
}
.tabs__tab {
  display: none;
}
.tabs__tab_active {
  display: block!important;
}
.tabs__toggle {
  display: none;
  position: absolute;
  top: 0;
  left: 12rem;
  width: 40px;
  height: 40px;
  background: url("/public/images/chevron.svg") no-repeat center;
  transform: rotate(180deg);
}
.tabs__content {
  margin-top: 16px;
}
@media screen and (max-width: 1280px) {
  .tabs__title,
  .tabs__title_active {
    font-size: 48px;
    line-height: 60px;
  }
}
@media screen and (max-width: 960px) {
  .tabs__title,
  .tabs__title_active {
    margin-right: 24px;
    font-size: 36px;
    line-height: 48px;
  }
}
@media screen and (max-width: 600px) {
  .tabs__header {
    position: relative;
  }
  .tabs__title {
    display: none;
    margin-right: 16px;
    font-size: 32px;
    line-height: 40px;
    border-bottom: none;
  }
  .tabs__title_active,
  .tabs__title_opened {
    display: block;
  }
  .tabs__toggle {
    display: block;
  }
  .tabs__content {
    margin-top: 24px;
  }
}
.browser {
  border: 1px solid #42A4F5;
  box-sizing: border-box;
  border-radius: 4px;
  padding-bottom: 4px;
}
.browser__controls {
  display: flex;
  justify-content: space-between;
  padding: 16px 12px;
  box-sizing: border-box;
}
.browser__control {
  display: block;
  flex-shrink: 0;
}
.browser__url {
  flex-shrink: 1;
  width: 100%;
  height: 32px;
  margin: 0 20px;
  background: rgba(39, 47, 90, 0.65);
  border-radius: 40px;
}
.browser__content {
  border-top: 1px solid #42A4F5;
  border-bottom: 1px solid #42A4F5;
}
.browser__item {
  position: relative;
  display: none;
}
.browser__item_active {
  display: block;
}
.browser__item-img {
  display: block;
  width: 100%;
  transition: opacity 150ms ease;
  will-change: opacity;
}
.browser__item-subimg {
  position: absolute;
  display: block;
  width: 100%;
  top: 0;
  z-index: -1;
}
@media screen and (max-width: 960px) {
  .browser__control {
    width: 24px;
    height: 24px;
  }
  .browser__url {
    width: 100%;
    height: 24px;
    margin: 0 15px;
  }
}
@media screen and (max-width: 600px) {
  .browser__controls {
    padding: 8px 6px;
  }
  .browser__control {
    width: 16px;
    height: 16px;
  }
  .browser__url {
    width: 100%;
    height: 16px;
    margin: 0 10px;
  }
}
.link {
  display: inline-block;
  line-height: 32px;
  font-size: 16px;
  font-family: PTRootUIWebBold, Helvetica, sans-serif;
  font-weight: bold;
  color: #fff;
  text-decoration: none;
  text-align: center;
}
@media screen and (max-width: 1280px) {
  .link {
    line-height: 24px;
    font-size: 14px;
  }
}
.link img {
  width: 24px;
  height: 24px;
  margin: 4px 8px 4px 0;
  vertical-align: top;
}
@media screen and (max-width: 1280px) {
  .link img {
    margin: 0 8px 0 0;
  }
}
.link_blue {
  color: #189EE9;
  text-decoration: none;
}
.link_blue img {
  width: 16px;
  height: 16px;
  margin: 8px 8px 8px 0;
}
@media screen and (max-width: 1280px) {
  .link_blue img {
    margin: 4px 8px 4px 0;
  }
}
.link-button,
.link-button_big {
  display: inline-block;
  padding: 8px 24px;
  border-radius: 4px;
  line-height: 32px;
  font-size: 16px;
  font-family: PTRootUIWebBold, Helvetica, sans-serif;
  font-weight: bold;
  color: #fff;
  background-color: #189EE9;
  text-decoration: none;
  text-align: center;
  border: none;
}
.link-button_big {
  padding: 16px 24px;
}
.field-input,
.field-textarea {
  display: block;
  width: 100%;
  border-radius: 4px;
  border: 1px solid rgba(29, 38, 84, 0.15);
  background-color: #fff;
  font-size: 18px;
  line-height: 32px;
  padding: 14px 24px;
  text-align: center;
  color: #272F5A;
  transition: border 150ms ease;
  box-sizing: border-box;
}
.field-input_blue,
.field-textarea_blue {
  text-align: left;
  border: 1px solid #189EE9;
}
.field-textarea {
  height: 120px;
  resize: none;
}
.field-error {
  border: 1px solid #e91616;
}
.subtitle,
.subtitle_white {
  font-size: 60px;
  line-height: 70px;
  text-align: center;
  color: #272F5A;
}
@media screen and (max-width: 960px) {
  .subtitle,
  .subtitle_white {
    font-size: 32px;
    line-height: 40px;
  }
}
.subtitle_white {
  color: #fff;
}
.modal {
  display: none;
  position: absolute;
  width: 100%;
  margin: 40px auto 80px;
  padding: 16px 16px 60px;
  background-color: #fff;
  box-sizing: border-box;
  opacity: 0;
  transition: opacity 0.3s ease, margin-top 0.3s ease;
  will-change: opacity, margin-top;
}
.modal__overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000;
  overflow: auto;
  background-color: rgba(30, 45, 110, 0.8);
  opacity: 0;
  transition: opacity 0.3s ease;
  will-change: opacity;
}
.modal__wrapper {
  position: relative;
  width: 600px;
  margin: auto;
}
.modal__top {
  text-align: right;
}
.modal__top-close {
  display: inline-block;
  width: 16px;
  height: 16px;
  cursor: pointer;
}
.modal__top-close img {
  width: 16px;
  height: 16px;
}
.modal__title {
  font-size: 32px;
  line-height: 48px;
  text-align: center;
  color: #272F5A;
  margin: 0 16px 32px;
}
.modal__content {
  margin: 0 16px;
}
.modal__text {
  margin: 0 16px;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: rgba(39, 47, 90, 0.65);
}
.modal__field {
  margin-bottom: 16px;
}
.modal__submit,
.modal__button {
  display: block;
  width: 100%;
  margin-top: 16px;
}
.modal__button {
  margin-top: 32px;
}
@media screen and (max-width: 960px) {
  .modal__wrapper {
    width: auto;
    margin: 0 24px;
  }
}
@media screen and (max-width: 600px) {
  .modal__title {
    font-size: 24px;
    line-height: 32px;
  }
}
.header__info {
  position: absolute;
  top: 272px;
  left: 50%;
  max-width: 600px;
}
@media screen and (max-width: 1500px) {
  .header__info {
    top: 180px;
  }
}
@media screen and (max-width: 1280px) {
  .header__info {
    top: 80px;
    left: 24px;
  }
}
@media screen and (max-width: 960px) {
  .header__info {
    max-width: 100%;
    left: 0;
    padding: 0 24px;
  }
}
@media screen and (max-width: 600px) {
  .header__info {
    top: 256px;
  }
}
@media screen and (max-width: 480px) {
  .header__info {
    top: 128px;
  }
}
.header__title {
  margin-bottom: 24px;
  font-size: 48px;
  line-height: 72px;
  color: #FFFFFF;
}
@media screen and (max-width: 1280px) {
  .header__title {
    margin-bottom: 16px;
    font-size: 32px;
    line-height: 40px;
  }
}
@media screen and (max-width: 480px) {
  .header__title img {
    width: 112px;
  }
}
.header__title img:first-child {
  margin-right: 32px;
}
.header__text {
  font-size: 18px;
  line-height: 32px;
  color: #CFD5F3;
}
@media screen and (max-width: 1280px) {
  .header__text {
    font-size: 16px;
    line-height: 24px;
  }
}
@media screen and (max-width: 600px) {
  .header__text {
    font-size: 14px;
    line-height: 24px;
  }
}
.head-desktop {
  display: block;
  width: 100%;
}
.head-desktop svg {
  min-width: auto;
  position: absolute;
  top: 0;
  left: 0;
}
.ie-svg-hack {
  width: 100%;
}
.head-mobile {
  display: none;
}
@media screen and (min-width: 601px) and (max-width: 960px) {
  .head-desktop {
    display: flex;
    justify-content: center;
    overflow: hidden;
  }
  .head-desktop .ie-svg-hack {
    min-width: 960px;
  }
}
@media screen and (max-width: 600px) {
  .head-desktop {
    display: none;
  }
  .head-mobile {
    display: block;
  }
}
.footer {
  height: 1156px;
  margin-top: -250px;
  background: url("/public/images/footer_back.svg") repeat center top;
  padding-top: 250px;
}
.footer__main {
  width: 1200px;
  margin: 260px auto 0;
}
.footer__title {
  font-size: 60px;
  line-height: 80px;
  text-align: center;
  color: #FFFFFF;
}
.footer__sub-title {
  font-size: 36px;
  line-height: 48px;
  text-align: center;
  color: #CFD5F3;
}
.footer__menu {
  margin: 190px 56px 0;
}
.footer__menu:after {
  content: '';
  display: table;
  clear: both;
}
.send-message {
  margin: 48px auto 0;
  width: 600px;
}
.send-message__field {
  margin-bottom: 16px;
}
.send-message__submit {
  margin-top: 16px;
  width: 100%;
}
@media screen and (max-width: 1280px) {
  .footer__main {
    width: auto;
    padding: 24px;
  }
  .footer__title {
    font-size: 48px;
    line-height: 72px;
  }
  .footer__sub-title {
    font-size: 32px;
    line-height: 40px;
  }
  .footer__menu {
    margin: 210px 24px 0;
  }
}
@media screen and (max-width: 960px) {
  .footer {
    margin-top: -285px;
  }
  .footer__title {
    font-size: 32px;
    line-height: 40px;
  }
  .footer__sub-title {
    margin-top: 16px;
    font-size: 14px;
    line-height: 16px;
  }
  .footer__menu {
    margin-top: 60px;
  }
  .send-message {
    width: 100%;
  }
}
@media screen and (max-width: 600px) {
  .footer {
    margin-top: -320px;
  }
  .footer__main {
    margin-top: 200px;
  }
}
@media screen and (max-width: 480px) {
  .send-message {
    margin-top: 32px;
  }
  .send-message__submit {
    margin-top: 8px;
  }
}
.evo__title {
  float: left;
  width: 580px;
  font-size: 60px;
  line-height: 70px;
  color: #272f5a;
  margin-bottom: 60px;
}
.evo__image {
  width: 580px;
  float: right;
}
.evo__list {
  width: 580px;
  font-size: 16px;
  line-height: 24px;
  color: #272f5a;
}
.evo__list li {
  margin-bottom: 16px;
  margin-left: 18px;
}
.evo__list li:before {
  content: "";
  display: inline-block;
  width: 4px;
  height: 4px;
  border-radius: 4px;
  margin-right: 15px;
  margin-left: -18px;
  background-color: #189ee9;
}
.team {
  margin-top: 112px;
}
.team__title {
  font-size: 60px;
  line-height: 70px;
  text-align: center;
  color: #272f5a;
}
.team__list {
  margin-top: 48px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.team-participant {
  width: 30%;
  height: auto;
  border: 1px solid rgba(29, 38, 84, 0.15);
  border-radius: 4px;
  margin-bottom: 48px;
  box-sizing: border-box;
}
.team-participant__empty {
  width: 30%;
  min-height: 100px;
  border: none;
}
.team-participant__photo {
  width: 100%;
}
.team-participant__photo-hidden {
  visibility: hidden;
}
.team-participant__name {
  padding: 32px 32px;
  font-family: PTRootUIWebBold, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #272f5a;
}
.team-participant__position {
  padding: 8px 32px 0;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: rgba(39, 47, 90, 0.65);
}
.team-participant__info {
  padding: 24px 32px 48px;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: rgba(39, 47, 90, 0.65);
}
.bottom-button {
  text-align: center;
}
@media screen and (max-width: 1280px) {
  .evo__title {
    float: none;
    width: auto;
  }
  .evo__image {
    display: block;
    width: 50%;
    margin: 0 auto 60px;
    float: none;
  }
  .evo__list {
    width: auto;
    font-size: 16px;
  }
}
@media screen and (max-width: 960px) {
  .evo__image {
    width: 70%;
  }
  .team {
    margin-top: 80px;
  }
  .team-participant {
    width: 46%;
  }
}
@media screen and (max-width: 600px) {
  .evo__title {
    font-size: 48px;
    line-height: 56px;
    margin-bottom: 32px;
  }
  .evo__image {
    width: 100%;
    margin: 0 auto 32px;
  }
  .team {
    margin-top: 60px;
  }
  .team__title {
    font-size: 48px;
    line-height: 56px;
  }
  .team__list {
    margin-top: 32px;
  }
  .team-participant {
    width: 100%;
    margin-bottom: 32px;
  }
}
@media screen and (max-width: 480px) {
  .evo__title {
    font-size: 32px;
    line-height: 40px;
  }
  .evo__list {
    font-size: 14px;
    line-height: 16px;
  }
  .team {
    margin-top: 112px;
  }
  .team__title {
    font-size: 32px;
    line-height: 40px;
  }
  .team__list {
    margin-top: 36px;
  }
  .team-participant {
    height: 475px;
    margin-bottom: 16px;
  }
  .team-participant__name {
    padding: 24px 16px 0;
    font-size: 16px;
    line-height: 24px;
  }
  .team-participant__position {
    padding: 0 16px;
    font-size: 14px;
    line-height: 16px;
  }
  .team-participant__info {
    padding: 16px 16px 48px;
    font-size: 14px;
    line-height: 16px;
  }
}
@media screen and (max-width: 360px) {
  .team-participant {
    height: 350px;
  }
}
.version {
  margin-bottom: 80px;
}
@media screen and (max-width: 600px) {
  .version {
    margin-bottom: 60px;
  }
}
.version__title {
  font-size: 60px;
  line-height: 70px;
  color: #272F5A;
}
@media screen and (max-width: 600px) {
  .version__title {
    font-size: 32px;
    line-height: 40px;
  }
}
@media screen and (min-width: 481px) {
  .version__divider {
    display: none;
  }
}
@media screen and (max-width: 480px) {
  .version__divider {
    display: block;
  }
}
.version__actual {
  display: inline-block;
  margin-top: 26px;
  vertical-align: top;
  margin-left: 24px;
  padding: 4px 16px;
  background-color: #79AFFF;
  border-radius: 2px;
  font-size: 14px;
  line-height: 24px;
  color: #fff;
}
@media screen and (max-width: 600px) {
  .version__actual {
    font-size: 12px;
    line-height: 16px;
    margin-top: 8px;
    margin-left: 8px;
    padding: 4px 8px;
  }
}
@media screen and (max-width: 480px) {
  .version__actual {
    margin-left: 0;
  }
}
.version__date {
  margin-top: 8px;
  font-size: 16px;
  line-height: 24px;
  color: rgba(39, 47, 90, 0.65);
}
.version__features,
.version__fixes {
  display: inline-block;
  width: 580px;
  margin-top: 32px;
  vertical-align: top;
}
@media screen and (max-width: 960px) {
  .version__features,
  .version__fixes {
    width: auto;
  }
}
@media screen and (max-width: 600px) {
  .version__features,
  .version__fixes {
    margin-top: 24px;
  }
}
.version__features {
  margin-right: 40px;
}
@media screen and (max-width: 600px) {
  .version__features {
    margin-right: 0;
  }
}
.version__change-title {
  font-family: PTRootUIWebBold, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  color: #272F5A;
}
@media screen and (max-width: 600px) {
  .version__change-title {
    font-size: 14px;
    line-height: 16px;
  }
}
.version__points {
  margin-top: 12px;
  font-size: 16px;
  line-height: 32px;
  color: rgba(39, 47, 90, 0.65);
}
@media screen and (max-width: 600px) {
  .version__points {
    font-size: 12px;
    line-height: 16px;
  }
}
.contacts__title {
  margin-top: 32px;
  font-family: PTRootUIWebBold, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  color: #272F5A;
}
.contacts__content {
  margin-top: 16px;
  font-size: 16px;
  line-height: 24px;
  color: rgba(39, 47, 90, 0.65);
}
.map {
  height: 600px;
  margin-top: 8px;
}
.map_closed {
  display: none;
}
.map__marker {
  width: 48px;
  height: 56px;
  background-size: cover;
}
#map-marker__nizhnekamsk {
  background: url("/public/images/map-icon-nizhnekamsk.png") no-repeat center;
}
#map-marker__innopolis {
  background: url("/public/images/map-icon-innopolis.png") no-repeat center;
}
.map-toggler,
.map-toggler_inactive {
  margin-top: 24px;
  font-family: PTRootUIWebBold, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 16px;
  line-height: 32px;
  color: #189EE9;
  cursor: pointer;
}
.map-toggler__label {
  margin-left: 8px;
}
.map-toggler__icon {
  width: 16px;
  height: 16px;
}
.map-toggler_inactive .map-toggler__icon {
  width: 16px;
  height: 16px;
  transform: rotate(180deg);
}
@media screen and (max-width: 960px) {
  .contacts__title {
    font-size: 18px;
    line-height: 32px;
  }
}
@media screen and (max-width: 600px) {
  .contacts__title {
    margin-top: 24px;
    font-size: 16px;
    line-height: 24px;
  }
  .contacts__content {
    margin-top: 8px;
    font-size: 14px;
    line-height: 16px;
  }
}
@media screen and (max-width: 480px) {
  .contacts__title {
    font-size: 16px;
    line-height: 16px;
  }
}
.main-header {
  height: 82vw;
}
.main-header__back {
  position: absolute;
  width: 100%;
  opacity: 0;
}
.main-header__sub-image {
  position: absolute;
  z-index: -2;
  opacity: 0;
  width: 100%;
  transition: opacity 150ms ease;
  will-change: opacity;
}
.main-header__main-image {
  position: absolute;
  width: 100%;
  z-index: -3;
}
.main-header__hidden-img {
  width: 100%;
}
.main-header__head-area {
  cursor: default;
}
.main-header__content {
  position: absolute;
  width: 100%;
  padding-top: 400px;
}
.main-header__content-inner {
  width: 1200px;
  margin: auto;
}
.main-header__title {
  font-size: 60px;
  line-height: 70px;
  text-align: center;
  color: #ffffff;
}
.main-header__subtitle {
  margin-top: 16px;
  font-size: 36px;
  line-height: 48px;
  text-align: center;
  color: #cfd5f3;
}
.main-header__controls {
  margin-top: 30px;
  text-align: center;
}
.main-header__demo {
  margin-right: 16px;
}
.main-problems__list {
  margin-top: 48px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.main-problems__solve {
  width: 580px;
  height: 400px;
  margin-bottom: 40px;
  border: 1px solid rgba(29, 38, 84, 0.15);
  box-sizing: border-box;
  padding: 0 36px 60px;
  text-align: center;
}
.main-problems__title {
  font-family: PTRootUIWebBold, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #272f5a;
}
.main-problems__text {
  margin-top: 8px;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: rgba(39, 47, 90, 0.65);
}
.main-advantages {
  margin-top: 56px;
}
.main-advantages__list {
  margin-top: 48px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.main-advantages__advantage {
  width: 390px;
  height: 600px;
  padding: 40px;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 4px;
  box-sizing: border-box;
  text-align: center;
}
.main-advantages__title {
  margin-top: 38px;
  font-family: PTRootUIWebBold, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #272f5a;
}
.main-mid-back {
  position: absolute;
  width: 100%;
  height: 2217px;
  margin-top: 940px;
  z-index: -1;
  background: #fff url("/public/images/index-mid-back.svg") repeat center;
}
.main-mid-back-mobile {
  position: absolute;
  display: none;
  width: 100%;
  margin-top: 1100px;
  z-index: -1;
}
.main-convenience {
  margin-top: 96px;
}
.main-convenience__content {
  position: relative;
}
.main-convenience__video {
  margin: 48px auto 0;
  display: flex;
  width: 100%;
  min-width: 200px;
  max-width: 700px;
}
.main-convenience__prev,
.main-convenience__next {
  position: absolute;
  width: 60px;
  height: 100%;
  opacity: 0.7;
  transition: opacity 0.3s ease, background-color 0.3s ease;
  will-change: opacity, background-color;
  cursor: pointer;
}
.main-convenience__prev:hover,
.main-convenience__next:hover {
  opacity: 1;
}
.main-convenience__prev {
  left: -60px;
  background: url("/public/images/chevron-left-white.svg") no-repeat center;
}
.main-convenience__next {
  top: 0;
  left: 100%;
  background: url("/public/images/chevron-right-white.svg") no-repeat center;
}
.main-convenience__browser {
  margin-top: 48px;
}
.main-convenience__get-demo {
  margin: 27px auto 0;
  text-align: center;
}
.main-about {
  margin-top: 317px;
}
.main-about__title {
  float: right;
  width: 580px;
  margin-bottom: 32px;
  text-align: left;
}
.main-about__image {
  width: 580px;
  float: left;
}
.main-about__text {
  float: right;
  width: 580px;
  text-align: left;
  font-size: 16px;
  line-height: 24px;
  color: rgba(39, 47, 90, 0.65);
}
.main-about__link {
  display: block;
  margin-top: 32px;
  text-align: left;
}
.main-about__clear {
  clear: both;
}
.main-get-pdf {
  margin-top: 117px;
}
.main-get-pdf__form {
  display: flex;
  justify-content: space-between;
  margin-top: 48px;
}
.main-get-pdf__field-name {
  width: 100%;
  flex-shrink: 2;
  margin-right: 16px;
}
.main-get-pdf__field-email {
  width: 100%;
  flex-shrink: 1;
  margin-right: 16px;
}
.main-get-pdf__submit {
  flex-shrink: 0;
}
@media screen and (max-width: 2560px) {
  .main-header {
    height: 90vw;
  }
  .main-header__content {
    padding-top: 256px;
  }
}
@media screen and (max-width: 1920px) {
  .main-header {
    height: 94vw;
  }
  .main-header__content {
    padding-top: 160px;
  }
}
@media screen and (max-width: 1500px) {
  .main-header {
    height: 97vw;
  }
  .main-header__content {
    width: 100%;
    padding-top: 100px;
  }
  .main-header__title {
    font-size: 48px;
    line-height: 56px;
  }
  .main-header__subtitle {
    font-size: 32px;
    line-height: 40px;
  }
  .main-mid-back {
    margin-top: 850px;
    background-size: contain;
    background-repeat: no-repeat;
  }
  .main-convenience {
    margin: 96px auto 0;
  }
  .main-convenience__browser {
    margin-top: 24px;
  }
  .main-convenience__browser-wrapper {
    position: relative;
    width: 800px;
    margin: auto;
  }
}
@media screen and (max-width: 1280px) {
  .main-header {
    height: 94vw;
  }
  .main-header__back {
    position: absolute;
    width: 100%;
    transition: opacity 150ms ease;
    will-change: opacity;
  }
  .main-header__sub-image {
    position: absolute;
    z-index: -1;
    opacity: 0;
    transition: opacity 150ms ease;
    will-change: opacity;
  }
  .main-header__content {
    width: 100%;
    padding: 100px 0 0;
  }
  .main-header__content-inner {
    width: auto;
  }
  .main-header__title {
    font-size: 32px;
    line-height: 40px;
  }
  .main-header__subtitle {
    font-size: 16px;
    line-height: 32px;
  }
  .main-problems__solve {
    width: 48%;
  }
  .main-problems__title {
    font-size: 18px;
    line-height: 32px;
  }
  .main-advantages {
    margin-top: 56px;
  }
  .main-advantages__list {
    justify-content: center;
  }
  .main-advantages__advantage {
    width: 100%;
    height: 420px;
    padding: 40px;
    margin-bottom: 24px;
  }
  .main-advantages__title {
    font-size: 18px;
    line-height: 32px;
  }
  .main-mid-back {
    margin-top: 1600px;
  }
  .main-convenience {
    margin: 96px auto 0;
  }
  .main-convenience__browser {
    margin-top: 24px;
  }
  .main-convenience__browser-wrapper {
    width: 700px;
  }
  .main-convenience__get-demo {
    margin: 18px auto 0;
  }
  .main-about {
    margin-top: 140px;
  }
  .main-about__title {
    float: none;
    width: auto;
    margin-bottom: 32px;
    text-align: center;
  }
  .main-about__image {
    display: block;
    width: 50%;
    margin: 0 auto 60px;
    float: none;
  }
  .main-about__text {
    float: none;
    width: auto;
    text-align: center;
  }
  .main-about__link {
    text-align: center;
  }
}
@media screen and (max-width: 960px) {
  .main-header {
    height: 100vw;
  }
  .main-problems__solve {
    width: 100%;
  }
  .main-mid-back {
    margin-top: 2316px;
  }
  .main-convenience {
    margin-top: 40px;
  }
  .main-convenience__browser {
    margin-top: 24px;
  }
  .main-convenience__browser-wrapper {
    width: 500px;
  }
  .main-about {
    margin-top: 80px;
  }
  .main-about__image {
    width: 70%;
  }
  .main-get-pdf {
    margin-top: 80px;
  }
  .main-get-pdf__form {
    display: block;
    margin-top: 32px;
  }
  .main-get-pdf__field-name {
    margin: 0 0 16px 0;
  }
  .main-get-pdf__field-email {
    margin: 0 0 24px 0;
  }
  .main-get-pdf__submit {
    display: block;
    width: 100%;
  }
}
@media screen and (max-width: 600px) {
  .main-header {
    height: 244vw;
  }
  .main-header__back,
  .main-header__sub-image {
    display: none;
  }
  .main-header__content {
    position: absolute;
    top: 256px;
    width: auto;
    padding: 0 24px;
  }
  .main-header__demo {
    margin-right: 0;
  }
  .main-header__download {
    display: block;
    margin-top: 16px;
  }
  .main-problems__list {
    margin-top: 32px;
  }
  .main-problems__solve {
    height: 336px;
    margin-bottom: 16px;
    padding: 0 16px 40px;
  }
  .main-problems__title {
    font-size: 14px;
    line-height: 16px;
  }
  .main-problems__text {
    margin-top: 16px;
    font-size: 12px;
    line-height: 16px;
  }
  .main-advantages__list {
    margin-top: 32px;
  }
  .main-advantages__advantage {
    height: 336px;
    padding: 26px;
  }
  .main-advantages__title {
    margin-top: 32px;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
  }
  .main-mid-back {
    display: none;
  }
  .main-mid-back-mobile {
    display: block;
  }
  .main-convenience {
    margin-top: 136px;
  }
  .main-convenience__prev,
  .main-convenience__next {
    width: 40px;
  }
  .main-convenience__prev {
    left: -40px;
  }
  .main-convenience__browser {
    margin-top: 32px;
  }
  .main-convenience__browser-wrapper {
    width: 79vw;
  }
  .main-convenience__get-demo {
    margin: 32px auto 0;
  }
  .main-about {
    margin-top: 420px;
  }
  .main-about__title {
    margin-bottom: 32px;
  }
  .main-about__image {
    margin-bottom: 24px;
  }
  .main-about__text {
    font-size: 12px;
    line-height: 16px;
  }
  .main-about__link {
    margin-top: 24px;
  }
  .main-about__clear {
    clear: both;
  }
}
@media screen and (max-width: 560px) {
  .main-about {
    margin-top: 520px;
  }
  .main-mid-back-mobile {
    margin-top: 1350px;
  }
}
@media screen and (max-width: 520px) {
  .main-about {
    margin-top: 390px;
  }
}
@media screen and (max-width: 480px) {
  .main-header__content {
    top: 128px;
  }
  .main-header__subtitle {
    font-size: 14px;
    line-height: 24px;
  }
  .main-header__controls {
    margin-top: 24px;
  }
  .main-problems__solve img {
    width: 100%;
  }
  .main-advantages__image {
    width: 100%;
    max-width: 226px;
  }
  .main-convenience {
    margin-top: 136px;
  }
  .main-convenience__browser-wrapper {
    width: 70vw;
  }
  .main-about {
    margin-top: 410px;
  }
  .main-mid-back-mobile {
    margin-top: 1680px;
  }
}
@media screen and (max-width: 440px) {
  .main-mid-back-mobile {
    margin-top: 1790px;
  }
  .main-about {
    margin-top: 390px;
  }
}
@media screen and (max-width: 420px) {
  .main-about {
    margin-top: 284px;
  }
}
@media screen and (max-width: 390px) {
  .main-mid-back-mobile {
    margin-top: 1920px;
  }
  .main-about {
    margin-top: 267px;
  }
}
@media screen and (max-width: 340px) {
  .main-about {
    margin-top: 160px;
  }
}
@media screen and (max-width: 320px) {
  .main-mid-back-mobile {
    margin-top: 2100px;
  }
  .main-about {
    margin-top: 214px;
  }
}
* {
  font-family: PTRootUIWebRegular, Helvetica, sans-serif;
}
body {
  background-color: #fff;
}
.content {
  width: 1200px;
  margin: -320px auto 0 auto;
  z-index: 100;
  transform: translateX(0);
}
@media screen and (max-width: 1500px) {
  .content {
    margin-top: -253px;
  }
}
@media screen and (max-width: 1280px) {
  .content {
    width: auto;
    padding: 24px 24px 0;
    margin-top: -212px;
  }
}
@media screen and (max-width: 600px) {
  .content {
    margin-top: -324px;
  }
}
@media screen and (max-width: 480px) {
  .content {
    margin-top: -188px;
  }
}
